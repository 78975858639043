<template>
  <div class="article" :class="{ active: fullScreen == true }">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <button class="btn_sub1" @click="fullScreen = !fullScreen">
          {{ fullScreen ? '닫기' : '전체화면' }}
        </button>
      </div>
      <div class="monitor_wrap">
        <div class="monitor_head">
          <h2>실시간 X-RAY검출 현황</h2>

          <span v-if="uwon_xray_log_now.length > 0"
            >조회시간 :
            {{ uwon_xray_log_now[0].create_time | formatDateHHMMSS }}</span
          ><span v-else>조회시간 : NaN</span>
        </div>
        <h3 class="product_title">
          진행 제품:
          {{
            uwon_xray_log_now.length > 0
              ? uwon_xray_log_now[0].product_name
              : 'loading'
          }}
        </h3>
        <div class="monitor_body">
          <div class="monitor_card">
            <h3>진행 수</h3>
            <p>
              {{
                uwon_xray_log_now.length > 0
                  ? $makeComma(
                      $makeNumber(uwon_xray_log_now[0].pass_count) +
                        $makeNumber(uwon_xray_log_now[0].unpass_count),
                    )
                  : 'loading'
              }}
            </p>
          </div>
          <div class="monitor_card good_card">
            <h3>양품</h3>
            <p>
              {{
                uwon_xray_log_now.length > 0
                  ? $makeComma($makeNumber(uwon_xray_log_now[0].pass_count))
                  : 'loading'
              }}
            </p>
          </div>
          <div class="monitor_card bad_card">
            <h3>불량</h3>
            <p>
              {{
                uwon_xray_log_now.length > 0
                  ? $makeComma($makeNumber(uwon_xray_log_now[0].unpass_count))
                  : 'loading'
              }}
            </p>
          </div>
          <div class="monitor_card bad_card">
            <h3>불량률</h3>
            <p>
              {{
                uwon_xray_log_now.length > 0
                  ? $makeComma(
                      (
                        ($makeNumber(
                          uwon_xray_log_now[0].unpass_count
                            ? uwon_xray_log_now[0].unpass_count
                            : 0,
                        ) /
                          ($makeNumber(
                            uwon_xray_log_now[0].unpass_count
                              ? uwon_xray_log_now[0].unpass_count
                              : 0,
                          ) +
                            $makeNumber(
                              uwon_xray_log_now[0].pass_count
                                ? uwon_xray_log_now[0].pass_count
                                : 1,
                            ))) *
                        100
                      ).toFixed(2),
                    )
                  : '0'
              }}%
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';

export default {
  mixins: [FETCH_MIXIN],
  data() {
    return {
      counter_interval: null,
      timer: 1,
      fullScreen: false,
    };
  },
  computed: {
    ...mapGetters({
      tabIndex: 'getOpenTabIndexFromPlcMonitorPage',
      products: 'getProduct',
      uwon_xray_log_now: 'getUwonXrayLogNow',
    }),
  },
  watch: {
    async timer(newValue) {
      if (newValue == 0 && this.tabIndex == 1) {
        await this.FETCH_NO_SPINNER('FETCH_UWON_XRAY_LOG_NOW', 'X-RAY 검출값');
        this.timer = 10;
      }
    },
  },
  async created() {
    await this.FETCH('FETCH_UWON_XRAY_LOG_NOW', 'X-RAY 검출값');
  },
};
</script>

<style lang="scss" scoped></style>
