<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <div>
          <div class="input_text">
            <label>조회 날짜</label>
            <input
              type="date"
              placeholder="YYYY-MM-DD"
              :value="selectDate"
              :max="maxDate"
              @change="setDate($event)"
            />
          </div>
        </div>
      </div>
      <h6>조회수 : {{ counter_logs_day.length }}건</h6>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th colspan="7">
                계수기 현황
              </th>
            </tr>
            <tr>
              <th>조회시간</th>
              <th>설비명</th>
              <th>Lot번호</th>
              <th>제품명</th>
              <th>규격</th>
              <th>진행수</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in counter_logs_day" :key="item.id">
              <td>
                {{ item.start_time | formatDateHHMMSS }} ~
                {{ item.end_time | formatDateHHMMSS }}
              </td>
              <td>
                {{ findInfoFromId(plc_sensors, item.plc_sensor_id).name }}
              </td>
              <td>
                {{ findInfoFromId(lots, item.lot_id).lot_number }} &nbsp;
                <button
                  class="btn_tbl"
                  @click="
                    trackingLotNum(findInfoFromId(lots, item.lot_id).lot_number)
                  "
                >
                  추적
                </button>
              </td>
              <td>
                {{ findInfoFromId(products, item.product_id).name }}
              </td>
              <td>
                {{ findInfoFromId(products, item.product_id).standard }}
              </td>
              <td>
                {{ $makeComma(item.value) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { yyyymmdd } from '@/utils/func';
import fetchMixin from '@/mixins/fetch';
import routes from '@/routes/routes';

export default {
  mixins: [fetchMixin],
  data() {
    return {
      maxDate: yyyymmdd(new Date()),
    };
  },
  computed: {
    ...mapGetters({
      plc_sensors: 'getPlcSensor',
      counter_logs_day: 'getCounterLogDay',
      selectDate: 'getSelectDateFromPlcCounterLog',
      lots: 'getLot',
      products: 'getProduct',
      plc_sensor_detail: 'getSensorDetailTypes',
    }),
  },
  methods: {
    async setDate(e) {
      this.$store.commit('setSelectDateToPlcCounterLog', e.target.value);
      await this.FETCH_WITH_PAYLOAD(
        'FETCH_COUNTER_LOG_DAY',
        this.selectDate,
        '계수기 현황',
      );
    },
    trackingLotNum(lotNum) {
      const goingPage = routes[0].children.find(
        x => x.path == '/product/tracking',
      );
      if (goingPage != undefined) {
        goingPage.meta.select = Number(lotNum.substr(8, 5));
      }
      this.$router.push('/product/tracking');
    },
  },
  async created() {
    if (this.plc_sensor_detail.length < 1)
      await this.FETCH('FETCH_PLC_SENSOR_DETAIL_TYPE', 'PLC 상세타입');

    if (this.lots.length < 1) await this.FETCH('FETCH_LOT_ALL', '작업지시');

    if (this.products.length < 1) await this.FETCH('FETCH_PRODUCT', '제품');

    if (this.selectDate == null) {
      this.$store.commit('setSelectDateToPlcCounterLog', this.maxDate);
      await this.FETCH_WITH_PAYLOAD(
        'FETCH_COUNTER_LOG_DAY',
        this.selectDate,
        '계수기 현황',
      );
    }
  },
};
</script>

<style lang="scss" scoped></style>
