<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <h5 class="title">에러 검출현황</h5>
        <div class="input_text">
          <label>조회기간</label>
          <input
            type="date"
            placeholder="YYYY-MM-DD"
            :value="start_date"
            @change="setStartDate($event)"
          />
          <p>~</p>
          <input
            type="date"
            placeholder="YYYY-MM-DD"
            :value="end_date"
            @change="setEndDate($event)"
          />
          <button class="btn_sub1" @click="refreshData()">검색</button>
        </div>
      </div>
      <h6>조회수 : {{ sorted_plc_error_logs.length }}건</h6>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th colspan="6">
                에러 검출현황 <span>조회시간 : {{ str_time }}</span>
              </th>
            </tr>
            <tr>
              <th>등록시간</th>
              <th>에러발생 센서</th>
              <th>검출 값</th>
              <th>구분</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in sorted_plc_error_logs" :key="item.id">
              <td>{{ item.create_time | formatDateHHMMSS }}</td>
              <td>
                {{ findInfoFromId(plc_sensors, item.plc_sensor_id).name }}
              </td>
              <td>{{ $makeComma(item.value) }}</td>
              <td>
                {{ item.plc_sensor_id == 15 ? '장비불량' : '기준값 초과' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import FETCH_MIXIN from '@/mixins/fetch';
import { yyyymmdd, yyyy년MM월dd일HH시mm분ss초 } from '@/utils/func';
import { mapGetters } from 'vuex';
export default {
  mixins: [FETCH_MIXIN],
  data() {
    return {
      str_time: null,
    };
  },
  computed: {
    ...mapGetters({
      plc_error_log: 'getPlcErrorLog',
      plc_sensors: 'getPlcSensor',
      start_date: 'getStartDateFromPlcErrorLog',
      end_date: 'getEndDateFromPlcErrorLog',
    }),
    sorted_plc_error_logs() {
      if (this.plc_error_log.length < 1) {
        return [];
      } else {
        return this.lodash
          .clonedeep(this.plc_error_log)
          .sort((a, b) => new Date(b.create_time) - new Date(a.create_time));
      }
    },
  },
  methods: {
    setStartDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.end_date) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최후날짜(${this.end_date})<br>이후의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.start_date;
        } else {
          this.$store.commit('setStartDateToPlcErrorLog', e.target.value);
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setStartDateToPlcErrorLog', yyyymmdd(new Date()));
      }
    },
    setEndDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.start_date) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최초날짜(${this.start_date})<br>이전의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.end_date;
        } else {
          this.$store.commit('setEndDateToPlcErrorLog', e.target.value);
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setEndDateToPlcErrorLog', yyyymmdd(new Date()));
      }
    },
    async refreshData() {
      this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
      await this.FETCH_WITH_PAYLOAD(
        'FETCH_PLC_ERROR_LOG_DAY',
        {
          start: this.start_date,
          end: this.end_date,
        },
        '에러 로그',
      );
    },
  },
  async created() {
    if (this.start_date == null) {
      const today = new Date();
      this.$store.commit('setStartDateToPlcErrorLog', yyyymmdd(today));
      this.$store.commit('setEndDateToPlcErrorLog', yyyymmdd(today));
    }
    if (this.plc_sensors.length < 1) {
      await this.FETCH('FETCH_PLC_SENSOR', '센서리스트');
    }
    await this.refreshData();
  },
};
</script>

<style lang="scss" scoped></style>
