<template>
  <div
    id="contents"
    :class="{
      status_board_woowon: tabIndex < 3,
      plc_th_status: tabIndex == 0,
      x_ray_status: tabIndex == 1 || tabIndex == 2,
      counter_status: tabIndex == 2,
      plc_th_log: tabIndex == 3,
      x_ray_log: tabIndex == 4,
      plc_package_log: tabIndex == 5,
      default_setting: tabIndex == 6,
      sensor_information: tabIndex == 7,
    }"
  >
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToPlcMonitorPage'"
          :options="[
            { title: '실시간 온습도 현황', name: 'th_status' },
            { title: '실시간 X-RAY 검출현황', name: 'r_x_ray_status' },
            { title: '실시간 자동포장기 현황', name: 'counter_status' },
            { title: '온습도 현황', name: 'th_log' },
            { title: 'X-RAY 검출현황', name: 'x_ray_status' },
            { title: '계수기 현황', name: 'plc_package_log' },
            { title: '기준값 설정', name: 'default_setting' },
            { title: '센서 에러현황', name: 'sensor_information' },
          ]"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li :class="{ active: tabIndex == 0 }">
            <a @click="SetOpenTabIndex(0)">실시간 온습도 현황</a>
          </li>
          <li :class="{ active: tabIndex == 1 }">
            <a @click="SetOpenTabIndex(1)">실시간 X-RAY 검출현황</a>
          </li>
          <li :class="{ active: tabIndex == 2 }">
            <a @click="SetOpenTabIndex(2)">실시간 자동포장기 현황</a>
          </li>
          <li :class="{ active: tabIndex == 3 }">
            <a @click="SetOpenTabIndex(3)">온습도 현황</a>
          </li>
          <li :class="{ active: tabIndex == 4 }">
            <a @click="SetOpenTabIndex(4)">X-RAY 검출현황</a>
          </li>
          <li :class="{ active: tabIndex == 5 }">
            <a @click="SetOpenTabIndex(5)">계수기 현황</a>
          </li>
          <li :class="{ active: tabIndex == 6 }">
            <a @click="SetOpenTabIndex(6)">기준값 설정</a>
          </li>
          <li :class="{ active: tabIndex == 7 }">
            <a @click="SetOpenTabIndex(7)">센서 에러현황</a>
          </li>
        </ul>
      </div>
      <temp-real-time-form v-if="tabIndex == 0"></temp-real-time-form>
      <xray-realtime-form v-if="tabIndex == 1"></xray-realtime-form>
      <counter-realtime-form v-if="tabIndex == 2"></counter-realtime-form>
      <temp-log-form v-if="tabIndex == 3"></temp-log-form>
      <plc-xray-status-form v-if="tabIndex == 4"></plc-xray-status-form>
      <plc-package-log-form v-if="tabIndex == 5"></plc-package-log-form>
      <default-plc-form v-if="tabIndex == 6"></default-plc-form>
      <plc-sensor-info-form v-if="tabIndex == 7"></plc-sensor-info-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import TempRealTimeForm from '@/views/forms/Custom/Monitor/15/TempRealTimeForm';
import PlcXrayStatusForm from '@/views/forms/Custom/Monitor/15/PlcXrayStatusForm';
import XrayRealtimeForm from '@/views/forms/Custom/Monitor/15/XrayRealtimeForm.vue';
import TempLogForm from '@/views/forms/Custom/Monitor/15/TempLogForm';
import DefaultPlcForm from '@/views/forms/Custom/Monitor/15/DefaultPlcForm';
import PlcSensorInfoForm from '@/views/forms/Custom/Monitor/15/PlcSensorInfoForm.vue';
import PlcPackageLogForm from '@/views/forms/Custom/Monitor/15/PlcPackageLogForm.vue';
import CounterRealtimeForm from '@/views/forms/Custom/Monitor/15/CounterRealtimeForm.vue';
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import FETCH_MIXIN from '@/mixins/fetch';

export default {
  mixins: [ClosePageMixin, FavoriteMixin, FETCH_MIXIN],
  components: {
    AsideSelectric,
    TempRealTimeForm,
    PlcXrayStatusForm,
    TempLogForm,
    DefaultPlcForm,
    XrayRealtimeForm,
    PlcSensorInfoForm,
    PlcPackageLogForm,
    CounterRealtimeForm,
  },
  computed: {
    ...mapGetters({
      tabIndex: 'getOpenTabIndexFromPlcMonitorPage',
    }),
  },
  methods: {
    ...mapMutations({
      SetOpenTabIndex: 'setOpenTabIndexToPlcMonitorPage',
    }),
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitPlcMonitorPage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style lang="scss">
@import 'woowon_monitoring';
</style>
