<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <h5 class="title">X-RAY 검출현황</h5>
      </div>

      <div class="info">
        <div class="input_text">
          <label>조회기간</label>
          <input
            type="date"
            placeholder="YYYY-MM-DD"
            :value="start_date"
            @change="setStartDate($event)"
          />
          <p>~</p>
          <input
            type="date"
            placeholder="YYYY-MM-DD"
            :value="end_date"
            @change="setEndDate($event)"
          />
          <button class="btn_sub1" @click="refreshData()">검색</button>
        </div>
      </div>
      <div class="x_ray_img"></div>
      <div>
        <h6>조회수 : {{ sorted_uwon_xray_logs.length }}건</h6>
        <div class="mes_tbl_wrap">
          <table class="mes_tbl">
            <thead>
              <tr>
                <th colspan="6">
                  X-RAY 검출현황 <span>조회시간 : {{ str_time }}</span>
                </th>
              </tr>
              <tr>
                <th>등록시간</th>
                <th>진행제품</th>
                <th>진행수</th>
                <th>양품</th>
                <th>불량</th>
                <th>불량률</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in sorted_uwon_xray_logs" :key="item.id">
                <td>{{ item.create_time | formatDateHHMMSS }}</td>
                <td>{{ item.product_name }}</td>
                <td>{{ $makeComma(item.pass_count + item.unpass_count) }}</td>
                <td>{{ $makeComma(item.pass_count) }}</td>
                <td>{{ $makeComma(item.unpass_count) }}</td>
                <td>
                  {{
                    item.pass_count + item.unpass_count
                      ? $makeComma(
                          (
                            (item.unpass_count /
                              (item.pass_count + item.unpass_count)) *
                            100
                          ).toFixed(2),
                        )
                      : 0
                  }}%
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FETCH_MIXIN from '@/mixins/fetch';
import { yyyymmdd, yyyy년MM월dd일HH시mm분ss초 } from '@/utils/func';
import { mapGetters } from 'vuex';
export default {
  mixins: [FETCH_MIXIN],
  data() {
    return {
      str_time: null,
    };
  },
  computed: {
    ...mapGetters({
      start_date: 'getStartDateFromUwonXrayLog',
      end_date: 'getEndDateFromUwonXrayLog',
      uwon_xray_log: 'getUwonXrayLog',
    }),
    sorted_uwon_xray_logs() {
      if (this.uwon_xray_log.length < 1) {
        return [];
      } else {
        return this.lodash
          .clonedeep(this.uwon_xray_log)
          .sort((a, b) => new Date(b.create_time) - new Date(a.create_time));
      }
    },
  },
  methods: {
    setStartDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.end_date) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최후날짜(${this.end_date})<br>이후의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.start_date;
        } else {
          this.$store.commit('setStartDateToUwonXrayLog', e.target.value);
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setStartDateToUwonXrayLog', yyyymmdd(new Date()));
      }
    },
    setEndDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.start_date) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최초날짜(${this.start_date})<br>이전의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.end_date;
        } else {
          this.$store.commit('setEndDateToUwonXrayLog', e.target.value);
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setEndDateToUwonXrayLog', yyyymmdd(new Date()));
      }
    },
    async refreshData() {
      this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
      await this.FETCH_WITH_PAYLOAD(
        'FETCH_UWON_XRAY_LOG',
        {
          start: this.start_date,
          end: this.end_date,
        },
        'X-RAY검출기 로그',
      );
    },
  },
  async created() {
    if (this.start_date == null) {
      const today = new Date();
      this.$store.commit('setStartDateToUwonXrayLog', yyyymmdd(today));
      this.$store.commit('setEndDateToUwonXrayLog', yyyymmdd(today));
    }
    await this.refreshData();
  },
};
</script>

<style lang="scss" scoped></style>
